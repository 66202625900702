<template>
    <vue-loading type="bars" color="#002B75" :size="{ width: '75px', height: '75px' }"></vue-loading>
</template>

<script>
// インポート
import { VueLoading } from 'vue-loading-template'
export default {
  name: 'app',
  // componentsに登録
  components: {
    VueLoading
  }
}
</script>