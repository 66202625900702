import Vue from 'vue';
import Loading from './components/Loading.vue';

// コンポーネントのグローバル登録
Vue.component('loading', Loading)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app',
    data: {
      loading: true,
      isActive: '1',
      show_modals: '0',
      show_contents: '1',
      show: true,
    },
    mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      changeModal: function (num) {
        this.show_modals = num
      },
      changeTabContNav: function(num){
        this.isActive = num
      },
      changeTabCont: function (num) {
        this.show_contents = num
      },
      closeMsg() {
        this.show = !this.show;
      }
    }
  })
})

